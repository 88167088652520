import { PaletteColorOptions } from '@mui/material';
import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';
import Pattern from '../assets/card-header-variant-1.svg';

export const colorPrimary = '#A3C074';
export const colorSecondary = '#434343';
export const defaultFontSize = 16;

declare module '@mui/material/styles' {
	interface Palette {
		light: PaletteColorOptions;
	}

	interface PaletteOptions {
		light: PaletteColorOptions;
	}
}

//***** TYPOGRAPHY *****//

declare module '@mui/material/styles' {
	interface TypographyVariants {
		subheadingL: React.CSSProperties;
		subheadingM: React.CSSProperties;
		subheadingS: React.CSSProperties;
		bodyL: React.CSSProperties;
		bodyM: React.CSSProperties;
		bodyS: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		subheadingL?: React.CSSProperties;
		subheadingM?: React.CSSProperties;
		subheadingS?: React.CSSProperties;
		bodyL?: React.CSSProperties;
		bodyM?: React.CSSProperties;
		bodyS?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		subheadingL: true;
		subheadingM: true;
		subheadingS: true;
		bodyL: true;
		bodyM: true;
		bodyS: true;
	}
}

//***** OTHER *****//

declare module '@mui/material/ButtonGroup' {
	interface ButtonGroupPropsColorOverrides {
		light: true;
	}
}

export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1170,
			xl: 1530,
		},
	},
});

export const themeOptions: ThemeOptions = {
	palette: {
		primary: {
			main: colorPrimary,
		},
		secondary: {
			main: colorSecondary,
		},
		action: {
			active: colorPrimary,
			hover: colorPrimary,
		},
		light: {
			main: '#636363',
		},
		text: {
			primary: '#636363',
		},
	},
	typography: {
		fontFamily: '"Inter", Calibri, sans-serif',
		fontSize: defaultFontSize,
		fontWeightBold: 700,
		htmlFontSize: defaultFontSize,
		h1: {
			font: 'var(--h1-bold)',
			letterSpacing: '-0.04em',
			[theme.breakpoints.down('md')]: {
				font: 'var(--h3-bold)',
			},
		},
		h2: {
			fontSize: 32,
			lineHeight: 1.25,
			marginBottom: 25,
		},
		h3: {
			fontSize: 28,
			lineHeight: 1.2,
			marginBottom: 25,
		},
		h4: {
			fontSize: 24,
			lineHeight: 1.25,
		},
		h5: {
			fontSize: 20,
			lineHeight: 1.2,
			fontWeight: 500,
			marginBottom: 5,
		},
		h6: {
			fontSize: defaultFontSize,
			lineHeight: 1.2,
			fontWeight: 700,
		},

		// Custom
		subheadingL: {
			fontSize: 22,
			lineHeight: '32px',
			display: 'block',
			[theme.breakpoints.down('md')]: {
				marginBottom: 'var(--spacer-l)',
				font: 'var(--subheading-s)',
			},
		},
		subheadingM: {
			fontSize: 20,
			lineHeight: '30px',
			display: 'block',
		},
		subheadingS: {
			fontSize: 18,
			lineHeight: '26px',
			display: 'block',
		},
		bodyL: {
			fontSize: 16,
			lineHeight: '24px',
			display: 'block',
		},
		bodyM: {
			fontSize: 14,
			lineHeight: '20px',
			display: 'block',
		},
		bodyS: {
			fontSize: 12,
			lineHeight: '18px',
			display: 'block',
		},

		// Default
		subtitle1: {
			fontSize: defaultFontSize,
			lineHeight: 1.25,
		},
		button: {
			fontSize: defaultFontSize + 2,
			lineHeight: 2,
		},
		caption: {
			fontSize: '0.8571428571428572rem',
		},
		body2: {
			fontSize: defaultFontSize,
		},
		body1: {
			fontSize: defaultFontSize,
		},
	},
	shape: {
		borderRadius: 0,
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					maxWidth: '804px',
					[theme.breakpoints.down('md')]: {
						marginTop: 0,
						marginBottom: 'var(--spacer-xs)',
					},
					'&.MuiContainer-maxWidthXl': {
						maxWidth: '1191px',
						padding: '30px',
						width: 'calc(100% - 130px)',
						[theme.breakpoints.down('lg')]: {
							width: 'calc(100% - 60px)',
							marginLeft: '30px',
						},
						[theme.breakpoints.down('md')]: {
							padding: 'var(--spacer)',
							marginLeft: 0,
							width: '100%',
						},
					},
					'&.MuiContainer-maxWidthLg': {
						maxWidth: 'none',
						padding: '30px',
						marginLeft: '70px',
						width: 'calc(100% - 130px)',
						[theme.breakpoints.down('lg')]: {
							width: 'calc(100% - 60px)',
							marginLeft: '30px',
						},
						[theme.breakpoints.down('md')]: {
							width: '100%',
							marginLeft: '0',
							padding: 'var(--spacer)',
						},
					},
					'&.MuiContainer-maxWidthMd': {
						maxWidth: '804px',
						padding: '30px',
						width: '100%',
						[theme.breakpoints.down('lg')]: {
							width: 'calc(100% - 60px)',
							marginLeft: '30px',
						},
						[theme.breakpoints.down('md')]: {
							width: '100%',
							marginLeft: '0',
							padding: 'var(--spacer)',
						},
					},
					'&.MuiContainer-maxWidthSm': {
						maxWidth: '1004px',
						padding: '30px',
						marginLeft: '70px',
						[theme.breakpoints.down('lg')]: {
							width: 'calc(100% - 60px)',
							marginLeft: '30px',
						},
						[theme.breakpoints.down('md')]: {
							width: '100%',
							marginLeft: '0',
							padding: 'var(--spacer)',
						},
					},
					'&.MuiContainer-maxWidthXs': {
						maxWidth: '804px',
						padding: '30px',
						marginLeft: '70px',
						[theme.breakpoints.down('md')]: {
							width: '100%',
							marginLeft: '0',
							padding: 'var(--spacer)',
						},
					},
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: colorSecondary,
					},
					'.icon': {
						display: 'inline-block',
						marginRight: '8px',
					},
					'&.Mui-selected, &:hover': {
						backgroundColor: 'var(--primary-050)',
						'.icon': {
							color: 'var(--primary-800)',
						},
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.component === 'th' && {
						fontWeight: 'bold',
						borderBottomColor: 'var(--grey-100)',
					}),
					padding: '8px 0',
					color: 'var(--grey-800)',
					'.MuiButtonBase-root': {
						paddingRight: '8px',
						paddingLeft: '8px',
						fontWeight: 'bold',
					},
				}),
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&:last-child td, &:last-child th': {
						borderBottom: 'solid 1px var(--grey-100) !important',
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: colorSecondary,
					},
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					minHeight: '56px',
					maxHeight: '56px',
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '0 var(--px) var(--px)',
				},
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: {
					fontWeight: 'bold',
					fontSize: '16px',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					paddingRight: 'var(--spacer)',
					paddingLeft: 'var(--spacer)',
					minHeight: '40px',
					minWidth: '0',
					maxHeight: '40px',
					borderRadius: '8px',
					fontWeight: 'bold',
					fontSize: '16px',

					'.icon': {
						fontSize: '24px',
						display: 'inline-block',
						marginRight: '8px',
						lineHeight: 0,

						'&.small': {
							fontSize: '14px',
						},
					},

					...(ownerState.variant === 'contained' && {
						textTransform: 'none',
						minHeight: '40px',
						color: '#fff',
						'&:hover': {
							color: '#fff',
						},
					}),

					// Primary
					...(ownerState.variant === 'contained' &&
						ownerState.color === 'primary' && {
							background: 'var(--gradient-default)',
							boxShadow: '0 4px 4px 0 var(--primary-100)',
							':hover': {
								background: 'var(--gradient-hovered)',
								boxShadow: '0 0 0 0 var(--primary-100)',
							},
							':focus': {
								background: 'var(--primary-800)',
								boxShadow: '0 0 0 0 var(--primary-100)',
							},
							'&:disabled': {
								background: 'var(--grey-100)',
								color: 'var(--grey-500)',
							},
						}),

					// Secondary
					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'primary' && {
							background: 'var(--grey-020)',
							border: 'solid 2px var(--primary-800)',
							color: 'var(--primary-800)',
							boxShadow: 'none',
							'&:hover': {
								background: 'var(--grey-020)',
								border: 'solid 2px var(--primary-900)',
								color: 'var(--primary-900)',
								boxShadow: 'none',
							},
							':focus': {
								border: 'solid 2px var(--primary-900)',
								background: 'var(--primary-050)',
								color: 'var(--primary-900)',
								boxShadow: 'none',
							},
							'&:disabled': {
								border: 'solid 2px var(--grey-500)',
								background: 'var(--grey-020)',
								color: 'var(--grey-500)',
								boxShadow: 'none',
							},
						}),

					// Tertiary
					...(ownerState.variant === 'contained' &&
						ownerState.color === 'secondary' && {
							background: 'var(--grey-050)',
							color: 'var(--grey-900)',
							borderRadius: 6,
							boxShadow: 'none',
							fontWeight: '400',
							font: 'var(--body-l)',
							'&:hover': {
								background: 'var(--grey-020)',
								color: 'var(--grey-900)',
								boxShadow: 'none',
							},
							':focus': {
								background: 'var(--grey-020)',
								color: 'var(--grey-900)',
								boxShadow: 'none',
							},
							'&:disabled': {
								background: 'var(--grey-020)',
								color: 'var(--grey-500)',
								boxShadow: 'none',
							},
						}),

					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'secondary' && {
							'&:hover': {
								color: colorSecondary,
							},
						}),

					...(ownerState.variant === 'outlined' && {
						textTransform: 'none',
					}),

					...(ownerState.variant === 'text' &&
						ownerState.color === 'primary' && {
							backgroundColor: 'var(--primary-050)',
							color: 'var(--grey-800)',
							' .icon': {
								color: 'var(--primary-800)',
							},
							'&:hover': {
								backgroundColor: 'var(--primary-100)',
								color: 'var(--primary-900)',
							},
						}),

					...(ownerState.variant === 'text' &&
						ownerState.color === 'secondary' && {
							color: 'var(--grey-800)',

							'&:hover': {
								backgroundColor: 'transparent',
								color: 'var(--primary-800)',
								' .icon': {
									color: 'var(--primary-800)',
								},
							},

							'&.active': {
								backgroundColor: 'var(--primary-050)',
								color: 'var(--grey-800)',
								' .icon': {
									color: 'var(--primary-800)',
								},
							},
						}),

					...(ownerState.variant === 'text' && {
						textTransform: 'none',
						fontSize: '16px',
						lineHeight: '24px',
						paddingRight: '20px',
						paddingLeft: '8px',
						fontWeight: 'normal',
						margin: 0,
						'.icon': {
							fontSize: '24px',
							display: 'inline-block',
							marginRight: '10px',
							lineHeight: 0,
						},
					}),

					...(ownerState.size === 'small' && {
						minHeight: '40px',
					}),
				}),
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					margin: 0,
					display: 'inline-flex',
					minWidth: '0',

					svg: {
						marginRight: '8px',
					},

					...(ownerState.variant === 'contained' &&
						ownerState.color === 'secondary' && {
							fontWeight: 'bold',
						}),
				}),
			},
		},
		MuiAppBar: {
			variants: [
				{
					props: {
						variant: 'outlined',
					},
					style: {
						backgroundColor: 'var(--grey-020)',
						color: colorSecondary,
						borderLeft: 'none',
						borderRight: 'none',
						borderTop: 'none',
						borderBottom: 'solid 1px var(--grey-300)',
					},
				},
			],
			defaultProps: {
				color: 'inherit',
			},
			styleOverrides: {
				colorInherit: {
					backgroundColor: colorSecondary,
					color: '#fff',
				},
			},
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: colorSecondary,
					},
					[theme.breakpoints.down('md')]: {
						font: 'var(--body-m)',
						marginBottom: 'var(--spacer-xs)',
						'.MuiTypography-root': {
							font: 'var(--body-m)',
						},
					},
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.primary && {
						marginBottom: 0,
						marginTop: 0,
					}),
					display: 'flex',
					flexDirection: 'column',
					gap: 'var(--spacer-xxs)',
				}),
				secondary: {
					font: 'var(--body-l)',
					color: 'var(--grey-800)',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paperAnchorBottom: {
					borderRadius: 0,
					'.MuiListItemIcon-root': {
						marginRight: 'var(--spacer-xs)',
						minWidth: 0,
					},
				},
				paperAnchorLeft: {
					borderRadius: 0,
					boxShadow: 'none',
				},
				root: {
					MuiListItemText: {
						marginBottom: 4,
					},
				},
				docked: {
					maxHeight: '100vh',
					position: 'sticky',
					left: 0,
					top: 0,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					paddingRight: '13px',
					paddingLeft: '13px',
					...(ownerState.color === 'primary' && {
						backgroundColor: 'var(--primary-700)',
						color: '#fff',
						' .MuiChip-label, svg': {
							fill: '#fff',
							color: '#fff',
						},
					}),
					...(ownerState.color === 'success' && {
						backgroundColor: 'var(--primary-700)',
						color: '#fff',
						' .MuiChip-label, svg': {
							fill: '#fff',
							color: '#fff',
						},
					}),
					...(ownerState.color === 'secondary' && {
						backgroundColor: 'var(--grey-200)',
						color: 'var(--grey-800)',
					}),
					fontSize: '1em',
				}),
				label: {
					textAlign: 'center',
					padding: 0,
				},
				icon: {
					margin: '0 3px 0 0',
					width: 'var(--spacer)',
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					lineHeight: '1.6em',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					letterSpacing: '-0.02em',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					padding: 0,
					borderRadius: '16px',
					border: 'solid 1px var(--primary-100)',
					boxShadow: '0 0 16px 0 rgba(63, 94, 43, 0.08)',
					'@media (max-width: 1120px)': {
						minWidth: '0',
					},
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					background: `url(${Pattern}) right top no-repeat var(--primary-050)`,
					padding: 'var(--py) var(--px)',
					[theme.breakpoints.down('md')]: {
						padding: 'var(--spacer)',
					},
					'.MuiCardHeader-title': {
						color: 'var(--primary-800)',
						font: 'var(--h4-bold)',
						[theme.breakpoints.down('md')]: {
							font: 'var(--h5-bold)',
						},
					},
					'.icon': {
						color: 'var(--primary-800)',
					},
					'.MuiCardHeader-avatar': {
						marginRight: 10,
					},
					'.icon-button': {
						textDecoration: 'none',
						marginRight: 'var(--spacer-xs)',
					},
					'.icon-button .icon': {
						color: 'var(--grey-800)',
					},
					'.MuiCardHeader-action': {
						display: 'flex',
						gap: 'var(--spacer-xs)',
						'.icon': {
							color: 'inherit',
						},
						'.MuiButton-root': {
							paddingRight: 'var(--spacer)',
							paddingLeft: 'var(--spacer-xs)',
							fontWeight: 'bold',
						},
					},
				},
				action: {
					margin: 0,
				},
				title: {
					textTransform: 'lowercase',
					'&:first-letter': {
						textTransform: 'uppercase',
					},
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 'var(--py) var(--px)',
					color: 'var(--grey-800)',
					[theme.breakpoints.down('md')]: {
						padding: 'var(--spacer)',
					},
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					display: 'flex',
					gap: '10px',
					padding: '0 var(--px) var(--py)',

					[theme.breakpoints.down('md')]: {
						padding: 'var(--spacer)',
					},
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					background: 'rgba(0, 0, 0, 0.6)',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 16,
					border: 'solid 1px var(--primary-100)',
					boxShadow: '0 0 16px 0 rgba(63, 94, 43, 0.16)',
					backgroundColor: 'var(--grey-020)',
					maxWidth: '480px',
				},

				root: ({ ownerState }) => ({
					...(ownerState.maxWidth === 'xl' && {
						'.MuiPaper-root': {
							maxWidth: '1200px',
						},
					}),
				}),
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: 'var(--px)',
					[theme.breakpoints.down('md')]: {
						padding: 'var(--spacer)',
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					backgroundColor: 'var(--primary-050)',
					font: 'var(--h4-bold)',
					color: 'var(--primary-800)',
					padding: '10px 10px 10px var(--px)',
					display: 'flex',
					alignItems: 'center',
					[theme.breakpoints.down('md')]: {
						padding: '10px 10px 10px var(--spacer)',
						font: 'var(--h5-bold)',
					},
					'.not-active': {
						color: 'var(--grey-800)',
						fontWeight: 'normal',
						letterSpacing: '0.35px',
					},
					'.active': {
						cursor: 'default',
					},
					a: {
						textDecoration: 'none',
						color: 'var(--primary-800)',
						transition: 'none',
					},
					span: {
						color: 'var(--grey-800)',
						fontWeight: 'normal',
						margin: '0 10px',
						display: 'inline-block',
					},
					'+ .MuiDialogContent-root': {
						padding: 'var(--px)',

						[theme.breakpoints.down('md')]: {
							padding: 'var(--spacer)',
							' .mt-4': {
								marginTop: '0 !important',
							},
						},
					},
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
					color: 'var(--grey-800)',
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
					color: 'var(--grey-800)',
					marginLeft: 0,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					border: 'solid 1px var(--grey-400)',
					borderRadius: '8px',
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					backgroundColor: 'var(--grey-050)',
					borderRadius: '8px',
					'.MuiInputBase-formControl': {
						lineHeight: '23px',
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: 'var(--grey-800)',
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					padding: '8px 16px',
					color: 'var(--grey-800)',
					textTransform: 'none',
					font: 'var(--body-l)',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: '16px',
					boxShadow: '0 0 16px 0 rgba(63, 94, 43, 0.08)',
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					'@media (max-width: 1120px)': {
						minWidth: '0',
					},
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				actions: {
					display: 'flex',
				},
			},
		},
		MuiList: {
			styleOverrides: {
				padding: {
					padding: '8px',
					'.MuiButtonBase-root': {
						padding: '3px 8px',
						borderRadius: '8px',
						color: 'var(--grey-800)',
					},
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: 'var(--grey-800)',
					'+ *': {
						marginTop: 'var(--spacer)',
					},
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					fontSize: '14px',
					lineHeight: '20px',
					color: 'var(--grey-900)',
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					fill: 'var(--grey-500)',
					height: '24px',
					width: '24px',
					'&.Mui-active circle': {
						fill: 'var(--primary-800)',
					},
				},
				text: {
					fill: 'var(--grey-020)',
					fontSize: '16px',
					lineHeight: '24px',
					fontWeight: 'bold',
				},
			},
		},
		MuiStep: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiStepper: {
			styleOverrides: {
				root: {
					background: 'var(--grey-050)',
					padding: 'var(--spacer)',
					gap: 'var(--spacer-xs)',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					borderRadius: '6px',
					fontSize: '14px',
				},
				standardError: {
					background: 'var(--alert-error)',
					color: 'var(--error-900)',
					'.MuiSvgIcon-root': {
						fill: 'var(--error-main)',
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: 0,
					svg: {
						marginRight: '4px',
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: 'var(--grey-900)',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderBottomWidth: '1px',
					borderColor: 'var(--grey-100)',
					marginBottom: '10px',
					marginTop: '10px',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					backgroundColor: 'var(--primary-050)',
					borderRadius: '8px',
					padding: '2px',
					minHeight: '0',
				},
				indicator: {
					display: 'none',
				},
				flexContainer: {
					gap: 'var(--spacer-xxs)',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					font: 'var(--body-l-bold)',
					color: 'var(--grey-800)',
					textTransform: 'none',
					borderRadius: '6px',
					padding: '6px 8px',
					minHeight: '36px',

					'&.Mui-selected': {
						backgroundColor: 'var(--grey-020)',
						color: 'var(--grey-800)',
					},
				},
			},
		},
		MuiGrid: {
			styleOverrides: {
				root: {
					'&.card-grid': {
						'.MuiCard-root': {
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						},
						'.MuiCardActions-root': {
							marginTop: 'auto',
							flexDirection: 'column',
							'@media (min-width: 900px)': {
								flexDirection: 'row',
							},
							[theme.breakpoints.down('md')]: {
								'button, a': {
									display: 'flex',
									width: '100%',
								},
							},
						},
					},
				},
			},
		},
		// all components here
	},
};
